// API error response
export type APIErrorResponse = {
    error: boolean;
    message: string;
    status?: number;
};

// API models for POST /authenticate
export type AuthAPIRequest = {
    email: string;
};

// API models for POST /device
export type RegisterDeviceAPIRequest = {
    email: string;
    verification_code: string;
    description: string;
    device_type: string;
};

export type RegisterDeviceAPIResponse = {
    uuid: string;
    device_token: string;
};

export interface Seat {
    email: string;
    last_accessed_at: string | null;
}

// API models for GET /device (an array of these)
export type GetDevicesAPIResponse = {
    uuid: string;
    description: string;
    last_accessed_at: Date;
};

export type GetDevicesAPIResponseArray = {
    devices: GetDevicesAPIResponse[];
};

// API models for POST /token
export type TokenAPIRequest = {
    email: string;
    device_token: string;
};

export type TokenAPIResponse = {
    api_token: string;
    device_uuid: string;
    email: string;
};

// API models for DELETE /device
export type DeleteDeviceAPIRequest = {
    uuid: string;
};

// API models for POST /x-progress
export type PostXProgressAPIRequest = {
    account_uuid: string;
    total_tweets_indexed: number;
    total_tweets_archived: number;
    total_retweets_indexed: number;
    total_likes_indexed: number;
    total_unknown_indexed: number;
    total_tweets_deleted: number;
    total_retweets_deleted: number;
    total_likes_deleted: number;
};

// API models for GET /user/premium
export type UserPremiumAPIResponse = {
    premium_price_cents: number;
    premium_business_price_cents: number;
    premium_access: boolean;
    has_individual_subscription: boolean;
    subscription_cancel_at_period_end: boolean;
    subscription_current_period_end: string;
    has_business_subscription: boolean;
    business_organizations: string[];
    partner: boolean;
};

// API models for POST /user/invoices (an array of these)
// API models for POST /organization/{uuid}/invoices (an array of these)
export type InvoicesAPIResponse = {
    created_at: string;
    hosted_invoice_url: string;
    status: string;
    total: number;
};

// API models for POST /user/premium
export type PostUserPremiumAPIRequest = {
    promotion_code: string;
};

export type PostUserPremiumAPIResponse = {
    redirect_url: string;
};

// API models for POST /user/coupon-code
// API models for POST /organization/coupon-code
export type PostCouponCodeAPIRequest = {
    promotion_code: string;
};

export type PostCouponCodeAPIResponse = {
    valid: boolean;
    currency?: string;
    duration?: string;
    duration_in_months?: number;
    amount_off?: number;
    percent_off?: number;
    name?: string;
};

// API models for GET /user/stats
export type UserStatsAPIResponse = {
    total_tweets_indexed: number;
    total_tweets_archived: number;
    total_retweets_indexed: number;
    total_likes_indexed: number;
    total_unknown_indexed: number;
    total_tweets_deleted: number;
    total_retweets_deleted: number;
    total_likes_deleted: number;
};

// API models for GET /organization/premium
export type OrganizationPremiumAPIResponse = {
    cyd_for_teams_price_cents: number;
    orgs: {
        uuid: string;
        name: string;
        has_active_subscription: boolean;
        number_of_seats: number;
        number_of_seats_filled: number;
        subscription_cancel_at_period_end: boolean;
        subscription_current_period_end: string;
        is_owner: boolean;
        owner_email: string;
    }[];
};

// API models for POST /organization/premium
export type PostOrganizationPremiumAPIRequest = {
    name: string;
    number_of_seats: number;
    promotion_code: string;
};

export type PostOrganizationPremiumAPIResponse = {
    redirect_url: string;
};

// API models for POST /organization/{uuid}/premium/preview
export type PostOrganizationPremiumPreviewAPIRequest = {
    number_of_seats: number;
};

export type PostOrganizationPremiumPreviewAPIResponse = {
    immediate_cost_cents: number;
    annual_cost_cents: number;
};

// API models for PUT /organization/{uuid}/premium
export type PutOrganizationPremiumAPIRequest = {
    action: string;
    number_of_seats?: number;
};

export type PutOrganizationPremiumAPIResponse = {
    message: string;
    redirect_url?: string;
};

// API models for PUT /organization/{uuid}
export type PutOrganizationAPIRequest = {
    name: string;
};

// API models for PUT /organization/{uuid}/owner
export type PutOrganizationOwnerAPIRequest = {
    new_owner_email: string;
};

// API models for GET /organization/{uuid}/invitations
// API models for GET /organization/{uuid}/admin-invitations
export type InvitationsAPIResponse = {
    id: number,
    email: string;
    token: string;
    created_at: string;
    expires_at: string;
    invited_by?: {
        email: string;
    }
};

// API models for POST /organization/{uuid}/invitations
// API models for POST /organization/{uuid}/admin-invitations
export type PostInvitationsAPIResponse = {
    message?: string;
    token?: string;
    error?: string;
};

// API models for POST /automation-error-report
export type PostAutomationErrorReportAPIRequest = {
    app_version: string;
    client_platform: string;
    account_type: string;
    error_report_type: string;
    error_report_data: object;
    account_username?: string;
    screenshot_data_uri?: string;
    sensitive_context_data?: object;
};

// API models for POST /newsletter
export type PostNewsletterAPIRequest = {
    email: string;
};

// POST /invitations/:token/accept
export type PostInvitationAcceptAPIRequest = {
    token: string;
};

export type PostInvitationAcceptAPIResponse = {
    message: string;
};

// API models for /organization/{uuid}/preferences
export type PreferencesAPIResponse = {
    new_seat_notifications: boolean;
    seats_full_notifications: boolean;
};

// POST /billing/portal
export type BillingPortalAPIResponse = {
    portal_url: string;
};

// POST /partner/coupon
export type PostPartnerCouponAPIRequest = {
    product: string;
    discount_type: string;
};

export type PostPartnerCouponAPIResponse = {
    code: string;
};

// GET /partner/coupon
export type GetPartnerCouponAPIResponse = {
    coupons: {
        code: string;
        product: string;
        discount_type: string;
        created_at: string;
        active: boolean;
        times_used: number;
    }[];
};

// The API client
export default class CydAPIClient {
    public apiURL: string | null = null;
    private userEmail: string | null = null;
    private deviceToken: string | null = null;
    private apiToken: string | null = null;
    private deviceUUID: string | null = null;

    constructor() {
        this.apiURL = null;
        this.userEmail = null;
        this.deviceToken = null;
        this.apiToken = null;
        this.deviceUUID = null;
    }

    initialize(APIURL: string): void {
        this.apiURL = APIURL;
    }

    setUserEmail(userEmail: string) {
        this.userEmail = userEmail;
    }

    setDeviceToken(deviceToken: string) {
        this.deviceToken = deviceToken;
    }

    getDeviceUUID(): string | null {
        return this.deviceUUID;
    }

    returnError(message: string, status?: number): APIErrorResponse {
        const apiErrorResponse: APIErrorResponse = {
            error: true,
            message: message,
            status: status
        }
        return apiErrorResponse
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetch(method: string, resource: RequestInfo, body: any): Promise<Response> {
        const options: RequestInit = {
            method: method,
            headers: {
                "Content-Type": "application/json"
            }
        };
        if (body !== null) {
            options.body = JSON.stringify(body);
        }
        return fetch(resource, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchAuthenticated(method: string, resource: RequestInfo, body: any): Promise<Response> {
        const options: RequestInit = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.apiToken
            }
        };
        if (body !== null) {
            options.body = JSON.stringify(body);
        }
        return new Promise((resolve, reject) => {
            fetch(resource, options).then(response => {
                if (response.status != 401) {
                    resolve(response);
                    return;
                }

                // Try to get a new token, and then try one more time
                console.log("Failed to authenticate with the server. Trying to get a new API token.");
                this.getNewAPIToken().then(success => {
                    if (success) {
                        console.log("Got a new API token. Retrying the request.")
                        fetch(resource, options).then(resolve, reject);
                    } else {
                        console.log("Failed to get a new API token.")
                        resolve(new Response(JSON.stringify({ "message": "Authentication failed" }), {
                            status: 401,
                            headers: { 'Content-type': 'application/json' }
                        }));
                    }
                });
            }, reject);
        });
    }

    async getNewAPIToken(): Promise<boolean> {
        console.log("Getting a new API token");
        if (
            typeof this.userEmail === 'string' && this.userEmail != '' &&
            typeof this.deviceToken === 'string' && this.deviceToken != ''
        ) {
            const getTokenResp = await this.getToken({
                email: this.userEmail,
                device_token: this.deviceToken
            });
            if ("error" in getTokenResp) {
                console.log("Failed to get a new API token", getTokenResp.message);
                return false;
            }
            this.apiToken = getTokenResp.api_token;
            return true;
        }
        return false;
    }

    async validateAPIToken(): Promise<boolean> {
        if (typeof this.apiToken === 'string') {
            return true;
        }
        return await this.getNewAPIToken();
    }

    // Auth API (not authenticated)

    async authenticate(request: AuthAPIRequest): Promise<boolean | APIErrorResponse> {
        console.log("POST /authenticate");
        try {
            const response = await this.fetch("POST", `${this.apiURL}/authenticate`, request);
            if (response.status != 200) {
                return this.returnError("Failed to authenticate with the server.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to authenticate with the server. Maybe the server is down?")
        }
    }

    async registerDevice(request: RegisterDeviceAPIRequest): Promise<RegisterDeviceAPIResponse | APIErrorResponse> {
        console.log("POST /device");
        try {
            const response = await this.fetch("POST", `${this.apiURL}/device`, request);
            if (response.status != 200) {
                return this.returnError("Failed to register device with the server.", response.status)
            }
            const data: RegisterDeviceAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to register device with the server. Maybe the server is down?")
        }

    }

    async getToken(request: TokenAPIRequest): Promise<TokenAPIResponse | APIErrorResponse> {
        console.log("POST /token");
        try {
            const response = await this.fetch("POST", `${this.apiURL}/token`, request);
            if (response.status != 200) {
                return this.returnError("Failed to get token with the server.", response.status)
            }
            const data: TokenAPIResponse = await response.json();

            this.apiToken = data.api_token;

            // Set the device UUID
            this.deviceUUID = data.device_uuid;
            return data;
        } catch {
            return this.returnError("Failed to get token with the server. Maybe the server is down?")
        }
    }

    // Auth API (authenticated)

    async deleteDevice(request: DeleteDeviceAPIRequest): Promise<void | APIErrorResponse> {
        console.log("DELETE /device");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/device`, request);
            if (response.status != 200) {
                return this.returnError("Failed to delete device with the server.", response.status)
            }
        } catch {
            return this.returnError("Failed to delete device with the server. Maybe the server is down?")
        }
    }

    async getDevices(): Promise<GetDevicesAPIResponseArray | APIErrorResponse> {
        console.log("GET /device");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/device`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get devices.", response.status)
            }
            const data: GetDevicesAPIResponse[] = await response.json();
            return { devices: data };
        } catch {
            return this.returnError("Failed to get devices. Maybe the server is down?")
        }
    }

    async ping(): Promise<boolean> {
        console.log("GET /ping");
        if (!await this.validateAPIToken()) {
            console.log("Failed to get a new API token.")
            return false;
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/ping`, null);
            return (response.status == 200);
        } catch {
            return false;
        }
    }

    // Submit progress

    async postXProgress(request: PostXProgressAPIRequest, authenticated: boolean): Promise<boolean | APIErrorResponse> {
        console.log("POST /x-progress", request);

        if (authenticated) {
            if (!await this.validateAPIToken()) {
                return this.returnError("Failed to get a new API token.")
            }
        }

        try {
            let response;
            if (authenticated) {
                response = await this.fetchAuthenticated("POST", `${this.apiURL}/x-progress`, request);
            } else {
                response = await this.fetch("POST", `${this.apiURL}/x-progress`, request);
            }

            if (response.status != 200) {
                return this.returnError("Failed to post XProgress with the server.", response.status)
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response) {
                const statusCode = error.response.status;
                const responseBody = error.response.data;
                return this.returnError(`Failed to post XProgress with the server. Status code: ${statusCode}, Response body: ${JSON.stringify(responseBody)}`);
            } else if (error.request) {
                return this.returnError("Failed to post XProgress with the server. No response received.");
            } else {
                return this.returnError(`Failed to post XProgress with the server. Error: ${error.message}`);
            }
        }

        return true;
    }

    // User API (authenticated)

    async getUserPremium(): Promise<UserPremiumAPIResponse | APIErrorResponse> {
        console.log("GET /user/premium");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/user/premium`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get user premium status.", response.status)
            }
            const data: UserPremiumAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get user premium status. Maybe the server is down?")
        }
    }

    async getUserInvoices(): Promise<InvoicesAPIResponse[] | APIErrorResponse> {
        console.log("GET /user/invoices");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/user/invoices`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get invoices.", response.status)
            }
            const data: InvoicesAPIResponse[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get invoices. Maybe the server is down?")
        }
    }

    async postUserPremium(promotion_code: string): Promise<PostUserPremiumAPIResponse | APIErrorResponse> {
        console.log(`POST /user/premium ${promotion_code}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/user/premium`, {
                promotion_code: promotion_code
            });
            if (response.status != 200) {
                return this.returnError("Failed to upgrade user to premium.", response.status)
            }
            const data: PostUserPremiumAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to upgrade user to premium. Maybe the server is down?")
        }
    }

    async putUserPremium(action: string): Promise<boolean | APIErrorResponse> {
        console.log("PUT /user/premium");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("PUT", `${this.apiURL}/user/premium`, { action: action });
            if (response.status != 200) {
                return this.returnError("Failed to update subscription.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to update subscription. Maybe the server is down?")
        }
    }

    async postUserCouponCode(promotion_code: string): Promise<PostCouponCodeAPIResponse | APIErrorResponse> {
        console.log("POST /user/coupon-code");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/user/coupon-code`, {
                promotion_code: promotion_code
            });
            if (response.status != 200) {
                return this.returnError("Failed to apply coupon code.", response.status)
            }
            const data: PostCouponCodeAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to apply coupon code. Maybe the server is down?")
        }
    }

    async deleteUserPremium(): Promise<boolean | APIErrorResponse> {
        console.log("DELETE /user/premium");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/user/premium`, null);
            if (response.status != 200) {
                return this.returnError("Failed to cancel subscription.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to cancel subscription. Maybe the server is down?")
        }
    }

    async getUserStats(): Promise<UserStatsAPIResponse | APIErrorResponse> {
        console.log("GET /user/stats");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/user/stats`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get user stats.", response.status)
            }
            const data: UserStatsAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get user stats. Maybe the server is down?")
        }
    }

    // Cyd for Teams API (authenticated)

    async getOrganizationPremium(): Promise<OrganizationPremiumAPIResponse | APIErrorResponse> {
        console.log("GET /organization/premium");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/premium`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get org premium status.", response.status)
            }
            const data: OrganizationPremiumAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get org premium status. Maybe the server is down?")
        }
    }

    async postOrganizationPremium(name: string, number_of_seats: number, promotion_code: string): Promise<PostOrganizationPremiumAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/premium ${promotion_code}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/premium`, {
                name: name,
                number_of_seats: number_of_seats,
                promotion_code: promotion_code
            });
            if (response.status != 200) {
                return this.returnError("Failed to buy Cyd for Teams.", response.status)
            }
            const data: PostOrganizationPremiumAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to buy Cyd for Teams. Maybe the server is down?")
        }
    }

    async putOrganizationPremium(uuid: string, action: string, number_of_seats: number | null = null): Promise<PutOrganizationPremiumAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/premium ${action} ${number_of_seats}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("PUT", `${this.apiURL}/organization/${uuid}/premium`, {
                action: action,
                number_of_seats: number_of_seats,
            });
            if (response.status != 200) {
                return this.returnError("Failed to update Cyd for Teams plan.", response.status)
            }
            const data: PutOrganizationPremiumAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to update Cyd for Teams plan. Maybe the server is down?")
        }
    }

    async deleteOrganizationPremium(uuid: string): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/premium`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/premium`, null);
            if (response.status != 200) {
                return this.returnError("Failed to cancel Cyd for Teams plan.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to cancel Cyd for Teams plan. Maybe the server is down?")
        }
    }

    async postOrganizationCouponCode(promotion_code: string): Promise<PostCouponCodeAPIResponse | APIErrorResponse> {
        console.log("POST /organization/coupon-code");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/coupon-code`, {
                promotion_code: promotion_code
            });
            if (response.status != 200) {
                return this.returnError("Failed to apply coupon code.", response.status)
            }
            const data: PostCouponCodeAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to apply coupon code. Maybe the server is down?")
        }
    }

    async postOrganizationPremiumPreview(uuid: string, number_of_seats: number): Promise<PostOrganizationPremiumPreviewAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/premium/preview ${number_of_seats}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/premium/preview`, {
                number_of_seats: number_of_seats,
            });
            if (response.status != 200) {
                return this.returnError("Failed to preview plan change.", response.status)
            }
            const data: PostOrganizationPremiumPreviewAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to preview plan change. Maybe the server is down?")
        }
    }

    async putOrganization(uuid: string, name: string): Promise<boolean | APIErrorResponse> {
        console.log(`PUT /organization/${uuid}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("PUT", `${this.apiURL}/organization/${uuid}`, {
                name: name
            });
            if (response.status != 200) {
                return this.returnError("Failed to update organization name.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to update organization name. Maybe the server is down?")
        }
    }

    async deleteOrganization(uuid: string): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}`, null);
            if (response.status != 200) {
                return this.returnError("Failed to delete organization.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete organization. Maybe the server is down?")
        }
    }

    async putOrganizationOwner(uuid: string, newOwnerEmail: string): Promise<boolean | APIErrorResponse> {
        console.log(`PUT /organization/${uuid}/owner`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("PUT", `${this.apiURL}/organization/${uuid}/owner`, {
                new_owner_email: newOwnerEmail
            });
            if (response.status != 200) {
                return this.returnError("Failed to update organization owner.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to update organization owner. Maybe the server is down?")
        }
    }

    async getSeats(uuid: string): Promise<Seat[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/seat`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/seat`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get list of seats.", response.status)
            }
            const data: Seat[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get list of seats. Maybe the server is down?")
        }
    }

    async postSeat(uuid: string, email: string): Promise<boolean | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/seat`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/seat`, {
                email: email
            });
            if (response.status != 201) {
                return this.returnError("Failed to create seat.", response.status)
            }
            return true;
        } catch (e) {
            return this.returnError("Failed to create seat. Maybe the server is down?")
        }
    }

    async deleteSeat(uuid: string, email: string): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/seat`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/seat`, {
                email: email
            });
            if (response.status != 200) {
                return this.returnError("Failed to delete seat.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete seat. Maybe the server is down?")
        }
    }

    async getSeatInvitations(uuid: string): Promise<InvitationsAPIResponse[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/invitations`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/invitations`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get list of invitations.", response.status)
            }
            const data: InvitationsAPIResponse[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get list of invitations. Maybe the server is down?")
        }
    }

    async postSeatInvitation(uuid: string, email: string): Promise<PostInvitationsAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/invitations`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/invitations`, {
                email: email
            });
            if (response.status != 200 && response.status != 201) {
                return this.returnError("Failed to create invitation.", response.status)
            }
            const data: PostInvitationsAPIResponse = await response.json();
            return data;
        } catch (e) {
            return this.returnError("Failed to create invitation. Maybe the server is down?")
        }
    }

    async deleteSeatInvitation(uuid: string, invitationID: number): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/invitations/${invitationID}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/invitations/${invitationID}`, null);
            if (response.status != 204) {
                return this.returnError("Failed to delete invitation.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete invitation. Maybe the server is down?")
        }
    }

    async getAdmins(uuid: string): Promise<string[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/admin`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/admin`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get list of admins.", response.status)
            }
            const data: string[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get list of admins. Maybe the server is down?")
        }
    }

    async postAdmin(uuid: string, email: string): Promise<boolean | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/admin`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/admin`, {
                email: email
            });
            if (response.status != 201) {
                return this.returnError("Failed to create admin.", response.status)
            }
            return true;
        } catch (e) {
            return this.returnError("Failed to create admin. Maybe the server is down?")
        }
    }

    async deleteAdmin(uuid: string, email: string): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/admin`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/admin`, {
                email: email
            });
            if (response.status != 200) {
                return this.returnError("Failed to delete admin.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete admin. Maybe the server is down?")
        }
    }

    async getAdminInvitations(uuid: string): Promise<InvitationsAPIResponse[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/admin-invitations`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/admin-invitations`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get list of admin invitations.", response.status)
            }
            const data: InvitationsAPIResponse[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get list of admin invitations. Maybe the server is down?")
        }
    }

    async postAdminInvitation(uuid: string, email: string): Promise<PostInvitationsAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/admin-invitations`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/admin-invitations`, {
                email: email
            });
            if (response.status != 200 && response.status != 201) {
                return this.returnError("Failed to create admin invitation.", response.status)
            }
            const data: PostInvitationsAPIResponse = await response.json();
            return data;
        } catch (e) {
            return this.returnError("Failed to create admin invitation. Maybe the server is down?")
        }
    }

    async deleteAdminInvitation(uuid: string, invitationID: number): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/admin-invitations/${invitationID}`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/admin-invitations/${invitationID}`, null);
            if (response.status != 204) {
                return this.returnError("Failed to delete invitation.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete invitation. Maybe the server is down?")
        }
    }

    async getOrganizationInvoices(uuid: string): Promise<InvoicesAPIResponse[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/invoices`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/invoices`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get invoices.", response.status)
            }
            const data: InvoicesAPIResponse[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get invoices. Maybe the server is down?")
        }
    }

    async getDomains(uuid: string): Promise<string[] | APIErrorResponse> {
        console.log(`GET /organization/${uuid}/domains`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${uuid}/domains`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get list of domains.", response.status)
            }
            const data: string[] = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get list of domains. Maybe the server is down?")
        }
    }

    async postDomain(uuid: string, domain: string): Promise<boolean | APIErrorResponse> {
        console.log(`POST /organization/${uuid}/domains`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${uuid}/domains`, {
                domain: domain
            });
            if (response.status == 400) {
                return this.returnError(`'${domain}' is not a valid domain name.`, response.status)
            }
            if (response.status == 409) {
                return this.returnError(`The domain '${domain}' is being used by a different team. If this is your domain, contact support@lockdown.systems.`, response.status)
            }
            if (response.status == 422) {
                return this.returnError(`The domain '${domain}' is a popular email provider. You can only add domains that are used specifically for your organization.`, response.status)
            }
            if (response.status != 201) {
                return this.returnError("Failed to create domain.", response.status)
            }
            return true;
        } catch (e) {
            return this.returnError("Failed to create domain. Maybe the server is down?")
        }
    }

    async deleteDomain(uuid: string, domain: string): Promise<boolean | APIErrorResponse> {
        console.log(`DELETE /organization/${uuid}/domains`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("DELETE", `${this.apiURL}/organization/${uuid}/domains`, {
                domain: domain
            });
            if (response.status != 200) {
                return this.returnError("Failed to delete domain.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to delete domain. Maybe the server is down?")
        }
    }

    // Submit automation error report

    async postAutomationErrorReport(request: PostAutomationErrorReportAPIRequest, authenticated: boolean): Promise<boolean | APIErrorResponse> {
        console.log("POST /automation-error-report", request, authenticated);

        if (authenticated) {
            if (!await this.validateAPIToken()) {
                return this.returnError("Failed to get a new API token.")
            }
        }

        try {
            let response;
            if (authenticated) {
                response = await this.fetchAuthenticated("POST", `${this.apiURL}/automation-error-report`, request);
            } else {
                response = await this.fetch("POST", `${this.apiURL}/automation-error-report`, request);
            }
            if (response.status != 200) {
                return this.returnError("Failed to post automation error report with the server.", response.status)
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response) {
                const statusCode = error.response.status;
                const responseBody = error.response.data;
                return this.returnError(`Failed to post automation error report with the server. Status code: ${statusCode}, Response body: ${JSON.stringify(responseBody)}`);
            } else if (error.request) {
                return this.returnError("Failed to post automation error report with the server. No response received.");
            } else {
                return this.returnError(`Failed to post automation error report with the server. Error: ${error.message}`);
            }
        }

        return true;
    }

    // Subscribe to newsletter

    async postNewsletter(request: PostNewsletterAPIRequest): Promise<boolean | APIErrorResponse> {
        console.log("POST /newsletter");
        try {
            const response = await this.fetch("POST", `${this.apiURL}/newsletter`, request);
            if (response.status != 200) {
                return this.returnError("Failed to subscribe to newsletter.", response.status)
            }
            return true;
        } catch {
            return this.returnError("Failed to subscribe to newsletter. Maybe the server is down?")
        }
    }

    // Invitations API

    async acceptInvitation(request: PostInvitationAcceptAPIRequest): Promise<PostInvitationAcceptAPIResponse | APIErrorResponse> {
        console.log(`POST /invitations/${request.token}/accept`);
        const url = `${this.apiURL}/invitations/${request.token}/accept`;
        const response = await this.fetch("POST", url, {});
        if (response.status != 200) {
            return this.returnError("Failed to accept invitation.", response.status)
        }
        const data: PostInvitationAcceptAPIResponse = await response.json();
        return data;
    }

    async acceptAdminInvitation(request: PostInvitationAcceptAPIRequest): Promise<PostInvitationAcceptAPIResponse | APIErrorResponse> {
        console.log(`POST /admin-invitations/${request.token}/accept`);
        const url = `${this.apiURL}/admin-invitations/${request.token}/accept`;
        const response = await this.fetch("POST", url, {});
        if (response.status != 200) {
            return this.returnError("Failed to accept admin invitation.", response.status)
        }
        const data: PostInvitationAcceptAPIResponse = await response.json();
        return data;
    }

    // Preferences
    async getPreferences(orgUuid: string): Promise<PreferencesAPIResponse | APIErrorResponse> {
        console.log(`GET /organization/${orgUuid}/preferences`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/organization/${orgUuid}/preferences`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get preferences.", response.status)
            }
            const data: PreferencesAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get preferences. Maybe the server is down?")
        }
    }

    async putPreferences(orgUuid: string, preferences: PreferencesAPIResponse): Promise<PreferencesAPIResponse | APIErrorResponse> {
        console.log(`PUT /organization/${orgUuid}/preferences`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("PUT", `${this.apiURL}/organization/${orgUuid}/preferences`, preferences);
            if (response.status != 200) {
                return this.returnError("Failed to update preferences.", response.status)
            }
            const data: PreferencesAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to update preferences. Maybe the server is down?")
        }
    }

    // Individual billing portal
    async createBillingPortalSession(returnUrl: string): Promise<BillingPortalAPIResponse | APIErrorResponse> {
        console.log("POST /billing/portal");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/billing/portal`, {
                return_url: returnUrl
            });
            if (response.status != 200) {
                return this.returnError("Failed to create billing portal session.", response.status)
            }
            const data: BillingPortalAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to create billing portal session. Maybe the server is down?")
        }
    }

    async createOrganizationBillingPortalSession(orgUuid: string, returnUrl: string): Promise<BillingPortalAPIResponse | APIErrorResponse> {
        console.log(`POST /organization/${orgUuid}/billing/portal`);
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/organization/${orgUuid}/billing/portal`, {
                return_url: returnUrl
            });
            if (response.status != 200) {
                return this.returnError("Failed to create organization billing portal session.", response.status)
            }
            const data: BillingPortalAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to create organization billing portal session. Maybe the server is down?")
        }
    }

    // Partner coupon codes
    async createPartnerCoupon(request: PostPartnerCouponAPIRequest): Promise<PostPartnerCouponAPIResponse | APIErrorResponse> {
        console.log("POST /partner/coupon");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("POST", `${this.apiURL}/partner/coupon`, request);
            if (response.status != 200) {
                return this.returnError("Failed to create partner coupon.", response.status)
            }
            const data: PostPartnerCouponAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to create partner coupon. Maybe the server is down?")
        }
    }

    async getPartnerCoupons(): Promise<GetPartnerCouponAPIResponse | APIErrorResponse> {
        console.log("GET /partner/coupon");
        if (!await this.validateAPIToken()) {
            return this.returnError("Failed to get a new API token.")
        }
        try {
            const response = await this.fetchAuthenticated("GET", `${this.apiURL}/partner/coupon`, null);
            if (response.status != 200) {
                return this.returnError("Failed to get partner coupons.", response.status)
            }
            const data: GetPartnerCouponAPIResponse = await response.json();
            return data;
        } catch {
            return this.returnError("Failed to get partner coupons. Maybe the server is down?")
        }
    }
}